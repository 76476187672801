<template>
    <div>
        <Menubar :model="navBarItems"
            class="w-full sticky top-0 z-50 min-h-[52px] dark:!bg-surface-0 dark:!text-surface-700 !border-none !rounded-none"
            :pt="menubarStyles">
            <template #start>
                <div class="flex items-center justify-between w-full px-1">
                    <div class="flex items-center gap-6">
                        <NuxtLink href="https://www.libe.quebec" target="_blank"
                            class="flex items-center py-2 rounded-md cursor-pointer">
                            <NuxtImg src="img/logoblack.png" alt="Libe" class="h-8 rounded-md" />
                        </NuxtLink>

                        <div>
                            <div class="flex flex-col md:flex-row md:items-center md:gap-2">
                                <span class="font-medium text-sm md:text-base">Signature électronique propulsée par
                                    Libe</span>
                                <NuxtLink href="https://www.libe.quebec" target="_blank"
                                    class="flex items-center gap-1 text-primary-500 hover:text-primary-600 transition-colors text-sm">
                                    <span>En savoir plus</span>
                                    <i class="pi pi-external-link text-xs" />
                                </NuxtLink>
                            </div>
                            <div class="flex items-center gap-1 text-xs md:text-sm">
                                <i class="pi pi-lock text-green-500" />
                                <span class="font-black">Connexion sécurisée</span>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </Menubar>
    </div>
</template>

<script setup lang="ts">
const navBarItems = [] as Array<any>;

const menubarStyles = {
    root: { class: 'bg-surface-0 dark:bg-surface-900' }
}
</script>