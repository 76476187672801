<template>
    <div class="h-screen w-full">
        <SignEmbedHeader />
        <slot />
    </div>
</template>

<script setup lang="ts">
window.addEventListener('message', (event) => {
    const type = event.data && event.data.type;
    const payload = event.data && event.data.payload;

    switch (type) {
        case 'session_view.document.loaded':
            console.log('Session view is loaded');
            break;
        case 'session_view.document.completed':
            console.log('Document is completed');
            console.log(payload);
            break;
        case 'session_view.document.exception':
            console.log('Exception during document finalization');
            console.log(payload);
    }
});
</script>